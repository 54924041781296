<template>
    <main>
        <Navbar />
        <div class="main">
            <div class="container">
                <div class="overlap" :class="{'overlap-fixed': platform == 'mobile', 'overlap-hide': !overLap}">
                    <div class="mt-3" v-if="platform == 'mobile'">
                        <div class="row">
                            <div class="col" :class="{'pr-0': status == 2 && total_bets}">
                                <button class="btn btn-info btn-sm btn-block" @click="overLapToggle">
                                    <i class="fas fa-filter mr-2"></i>{{ overLap ? 'Fechar filtros' : 'Abrir filtros' }}
                                </button>
                            </div>
                            <div class="col-auto" v-if="status == 2 && total_bets">
                                <button class="btn btn-danger btn-sm btn-block" @click="$modal.show('rank-prizes')">
                                    <i class="fas fa-hand-holding-usd mr-2"></i>Rank
                                </button>
                            </div>
                        </div>
                    </div><!-- /mt-3 -->
                    <div class="overlap-content">
                        <div class="btn-group w-100" :class="{'mt-3': platform == 'mobile'}" role="group" aria-label="Button group with nested dropdown">
                            <div class="btn-group w-100">
                                <button type="button" class="btn btn-secondary dropdown-toggle no-border-radius-right" id="dropDownBet1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" :class="{'active': status == 1 || isActive == 1}">
                                    <template v-if="platform == 'desktop'">
                                        <i class="fas fa-clock mr-2"></i>Aberta
                                    </template>
                                    <template v-else>Aberta</template>
                                    <span class="badge badge-warning float-right" :class="{'w-100': platform == 'mobile'}" v-show="(status == 1 || isActive == 1) && bets.length > 0">{{bets.length}}</span>
                                </button>
                                <div class="dropdown-menu" aria-labelledby="dropDownBet1">
                                    <a class="dropdown-item" href="javascript:;" @click="status = 1;inplay = false"><i class="far fa-list-alt mr-2"></i>Todas</a>
                                    <a class="dropdown-item" href="javascript:;" @click="filterBet(1)"><i class="fas fa-filter mr-2"></i>Filtrar</a>
                                    <div class="dropdown-divider"></div>
                                    <a class="dropdown-item" href="javascript:;" @click="filterLive(1)" :class="{'active': inplay && status == 1}"><i class="fas fa-signal-stream mr-2"></i>Ao vivo</a>
                                </div><!-- /dropdown-menu -->
                            </div>
                            <div class="btn-group w-100">
                                <button type="button" class="btn btn-secondary dropdown-toggle no-border-radius-right" id="dropDownBet2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" :class="{'active': status == 2 || isActive == 2}">
                                    <template v-if="platform == 'desktop'">
                                        <i class="fas fa-hand-holding-usd mr-2"></i>Ganhou
                                    </template>
                                    <template v-else>Ganhou</template>
                                    <span class="badge badge-warning float-right" :class="{'w-100': platform == 'mobile'}" v-show="(status == 2 || isActive == 2) && bets.length > 0">{{bets.length}}</span>
                                </button>
                                <div class="dropdown-menu" aria-labelledby="dropDownBet2">
                                    <a class="dropdown-item" href="javascript:;" @click="status = 2;inplay = false"><i class="far fa-list-alt mr-2"></i>Todas</a>
                                    <a class="dropdown-item" href="javascript:;" @click="filterBet(2)"><i class="fas fa-filter mr-2"></i>Filtrar</a>
                                    <div class="dropdown-divider"></div>
                                    <a class="dropdown-item" href="javascript:;" @click="filterLive(2)" :class="{'active': inplay && status == 2}"><i class="fas fa-signal-stream mr-2"></i>Ao vivo</a>
                                </div><!-- /dropdown-menu -->
                            </div>
                            <div class="btn-group w-100">
                                <button type="button" class="btn btn-secondary dropdown-toggle no-border-radius-right" id="dropDownBet3" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" :class="{'active': status == 3 || isActive == 3}">
                                    <template v-if="platform == 'desktop'">
                                        <i class="fas fa-sad-tear mr-2"></i>Perdeu
                                    </template>
                                    <template v-else>Perdeu</template>
                                    <span class="badge badge-warning float-right" :class="{'w-100': platform == 'mobile'}" v-show="(status == 3 || isActive == 3) && bets.length > 0">{{bets.length}}</span>
                                </button>
                                <div class="dropdown-menu" aria-labelledby="dropDownBet3">
                                    <a class="dropdown-item" href="javascript:;" @click="status = 3;inplay = false"><i class="far fa-list-alt mr-2"></i>Todas</a>
                                    <a class="dropdown-item" href="javascript:;" @click="filterBet(3)"><i class="fas fa-filter mr-2"></i>Filtrar</a>
                                    <div class="dropdown-divider"></div>
                                    <a class="dropdown-item" href="javascript:;" @click="filterLive(3)" :class="{'active': inplay && status == 3}"><i class="fas fa-signal-stream mr-2"></i>Ao vivo</a>
                                </div><!-- /dropdown-menu -->
                            </div>
                            <div class="btn-group w-100" v-if="platform == 'desktop'">
                                <button type="button" class="btn btn-secondary dropdown-toggle no-border-radius-right" id="dropDownBet4" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" :class="{'active': status == 4 || isActive == 4}">
                                    <i class="fas fa-ban mr-2"></i>Cancelada
                                    <span class="badge badge-warning float-right" :class="{'w-100': platform == 'mobile'}" v-show="(status == 4 || isActive == 4) && bets.length > 0">{{bets.length}}</span>
                                </button>
                                <div class="dropdown-menu" aria-labelledby="dropDownBet3">
                                    <a class="dropdown-item" href="javascript:;" @click="status = 4;inplay = false"><i class="far fa-list-alt mr-2"></i>Todas</a>
                                    <a class="dropdown-item" href="javascript:;" @click="filterBet(4)"><i class="fas fa-filter mr-2"></i>Filtrar</a>
                                    <div class="dropdown-divider"></div>
                                    <a class="dropdown-item" href="javascript:;" @click="filterLive(4)" :class="{'active': inplay && status == 4}"><i class="fas fa-signal-stream mr-2"></i>Ao vivo</a>
                                </div><!-- /dropdown-menu -->
                            </div>
                            <div class="btn-group" role="group">
                                <button id="btnGroupDrop1" type="button" class="btn btn-secondary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i class="fas fa-ellipsis-v mr-2"></i>Mais
                                </button>
                                <div class="dropdown-menu drop-left" aria-labelledby="btnGroupDrop1">
                                    <a class="dropdown-item" href="javascript:void(0);" v-if="platform == 'mobile'" @click="status = 4"><i class="fas fa-ban mr-2"></i>Cancelada</a>
                                    <div class="dropdown-divider" v-if="platform == 'mobile'"></div>
                                    <a class="dropdown-item" href="javascript:void(0);" :class="{'disabled': is_accumulated}" @click="live">
                                        <template v-if="!is_live">
                                            <i class="fas fa-tv mr-2"></i>Ao vivo
                                        </template>
                                        <template v-else>
                                            <i class="fas fa-undo mr-2"></i>Todos
                                        </template>
                                    </a>
                                    <div class="dropdown-divider"></div>
                                    <a class="dropdown-item" v-if="pipe == 3" href="javascript:void(0);" @click="delBet"><i class="fas fa-trash mr-2"></i>Excluir</a>
                                    <a class="dropdown-item" href="javascript:void(0);" @click="searchBet"><i class="fas fa-search mr-2"></i>Buscar</a>
                                    <template v-if="pipe == 3">
                                        <div class="dropdown-divider"></div>
                                        <div class="dropdown-header text-center text-uppercase">Filtrar</div>
                                        <div class="dropdown-divider"></div>
                                        <a class="dropdown-item" href="javascript:void(0);" @click.prevent="search = '';overLap = false"><i class="fas fa-filter mr-2"></i>Todos</a>
                                        <a class="dropdown-item" href="javascript:void(0);" @click.prevent="search = 'Criar aposta'"><i class="fas fa-filter mr-2"></i>Criar aposta</a>
                                        <a class="dropdown-item" href="javascript:void(0);" @click.prevent="search = 'Encerrada'"><i class="fas fa-filter mr-2"></i>Encerrada</a>
                                        <a class="dropdown-item" href="javascript:void(0);" @click.prevent="search = 'Vendedor'"><i class="fas fa-filter mr-2"></i>Vendedor</a>
                                        <a class="dropdown-item" href="javascript:void(0);" @click.prevent="search = 'Cliente'"><i class="fas fa-filter mr-2"></i>Cliente</a>
                                        <div class="dropdown-divider"></div>
                                        <a class="dropdown-item" href="javascript:void(0);" @click.prevent="filterByMoney"><i class="fas fa-search-dollar mr-2"></i>Por valor</a>
                                        <a class="dropdown-item" href="javascript:void(0);" @click.prevent="filterByHour"><i class="fas fa-clock mr-2"></i>Por horário</a>
                                        <template v-if="status == 2 && total_bets">
                                            <div class="dropdown-divider"></div>
                                            <a class="dropdown-item" href="javascript:void(0);" @click="$modal.show('rank-prizes')"><i class="fas fa-hand-holding-usd mr-2"></i>Rank de prêmios</a>
                                        </template>
                                    </template>
                                </div><!-- /dropdown-menu -->
                            </div><!-- /btn-group -->
                        </div><!-- /btn-group -->
                        <div class="mt-3 mb-3">
                            <div v-show="loading == true" class="alert alert-warning" role="alert">
                                <div class="alert-heading text-center mb-0">
                                    <div class="spinner-border mr-2 align-middle" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                    <span>Carregando, aguarde...</span>
                                </div>
                            </div><!-- /alert-warning -->
                            <div v-show="error.length > 0" class="alert alert-danger" role="alert">
                                <h4 class="alert-heading">Ops, ocorreu um problema!</h4>
                                <hr />
                                <p class="mb-0">{{error}}</p>
                            </div><!-- /alert-danger -->
                            <div v-show="total_bets == 0 && nothing_found == true" class="alert alert-info" role="alert">
                                <h4 class="alert-heading">Ops, ocorreu um problema!</h4>
                                <hr />
                                <p class="mb-0">Não foi possível achar nenhuma aposta para a data informada!</p>
                                <hr />
                                <div class="row">
                                    <div class="col pl-0" :class="{'d-contents': platform == 'mobile'}">
                                        <div class="input-group" :class="{'mb-3': platform == 'mobile'}">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text" style="width:63.84px">Ínicio</div>
                                        </div>
                                            <input class="form-control" type="date" v-model="initial_date">
                                        </div>
                                    </div>
                                    <div class="col" :class="{'d-contents': platform == 'mobile'}">
                                        <div class="input-group" :class="{'mb-3': platform == 'mobile'}">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text" style="width:63.84px">Fim</div>
                                        </div>
                                            <input class="form-control" type="date" v-model="final_date">
                                        </div>
                                    </div>
                                    <div class="col" :class="{'d-contents': platform == 'mobile'}">
                                        <button class="btn btn-warning btn-block" @click="searchDayBets"><i class="fas fa-search mr-2"></i>Buscar</button>
                                    </div>
                                </div>
                            </div><!-- /alert-danger -->
                            <div v-show="total_bets > 0 && nothing_found == false">
                                <div class="row ml-0 mb-3 mr-0">
                                    <div class="col pl-0" :class="{'d-contents': platform == 'mobile'}">
                                        <div id="virtual-select" :class="{'mb-3': platform == 'mobile'}"></div>
                                    </div><!-- /col -->
                                    <div class="col pl-0" :class="{'d-none': platform == 'mobile'}">
                                        <input class="form-control" type="text" v-model="search" :class="{'mb-3': platform == 'mobile'}" placeholder="Buscar vendedor, apostador...">
                                    </div><!-- /col -->
                                    <div class="col-auto pl-0" :class="{'d-contents': platform == 'mobile'}">
                                        <div class="btn-group w-100" :class="{'mb-3': platform == 'mobile'}">
                                            <button type="button" class="btn btn-block btn-danger dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <template v-if="platform == 'desktop'">
                                                    <i class="fas fa-filter pl-1"></i>
                                                </template>
                                                <template v-else>
                                                    <i class="fas fa-filter mr-2"></i>Filtrar
                                                </template>
                                            </button>
                                            <div class="dropdown-menu dropdown-menu-right">
                                                <h6 class="dropdown-header">Filtrar por</h6>
                                                <div class="dropdown-divider"></div>
                                                <button class="dropdown-item" type="button" @click="filterByMoney"><i class="fas fa-search-dollar mr-2"></i>Valor</button>
                                                <button class="dropdown-item" type="button" @click="filterByHour"><i class="fas fa-clock mr-2"></i>Horário</button>
                                                <template v-if="status == 2 && total_bets">
                                                    <div class="dropdown-divider"></div>
                                                    <button class="dropdown-item" type="button" @click="$modal.show('rank-prizes')"><i class="fas fa-hand-holding-usd mr-2"></i>Rank de prêmios</button>
                                                </template>
                                            </div>
                                        </div>
                                    </div><!-- /col -->
                                    <div class="col pl-0" :class="{'d-contents': platform == 'mobile'}">
                                        <input class="form-control" :class="{'mb-3': platform == 'mobile'}" v-model="initial_date" @change="listBets" type="date" id="date">
                                    </div><!-- /col -->
                                    <div class="col pl-0 pr-0" :class="{'d-contents': platform == 'mobile'}">
                                        <input class="form-control" v-model="final_date" @change="listBets" type="date" id="date">
                                    </div><!-- /col -->
                                </div><!-- /row -->
                            </div><!-- /total_bets -->
                        </div><!-- /mt-3 & mb-3 --> 
                    </div><!-- /overlap-content -->
                </div><!-- /overlap -->
                <template v-if="filteredListBets.length">
                    <collection-cluster v-bind="collection" :items="filteredListBets" @scrollToBottom="onScrollToBottom" :style="{height: screen_innerHeight + 'px'}" :class="{'desktop': platform == 'desktop'}">
                        <div class="row row-item w-100 m-0" slot="item" slot-scope="{ cell, item }" :key="cell.index" :ref="item.id" :class="{'winner': status == 2}">
                            <template v-if="cell.index == 0">
                                <div class="row row-salesman w-100 m-0" :class="{'bg-light': item.tipo_de_conta == 'cliente'}">
                                    <div class="col text-dark text-truncate font-weight-bold">{{item.salesman}}</div>
                                    <div class="col-auto text-dark font-weight-bold">
                                        <i class="fas fa-map-marker-alt"></i>
                                    </div>
                                    <div class="col text-dark text-truncate font-weight-bold">{{item.address}}</div>
                                </div>
                            </template>
                            <template v-else-if="item.vendedor_id !== filteredListBets[cell.index - 1].vendedor_id">
                                <div class="row row-salesman w-100 m-0" :class="{'bg-light': item.tipo_de_conta == 'cliente'}">
                                    <div class="col text-dark text-truncate font-weight-bold">{{item.salesman}}</div>
                                    <div class="col-auto text-dark font-weight-bold">
                                        <i class="fas fa-map-marker-alt"></i>
                                    </div>
                                    <div class="col text-dark text-truncate font-weight-bold">{{item.address}}</div>
                                </div>
                            </template>
                            <div class="row row-category w-100 m-0" :class="{'d-block': platform == 'mobile'}">
                                <div class="col text-truncate" :class="{'text-danger': platform == 'mobile'}">
                                    <span>{{ item.time_table }}</span>
                                    <hr v-if="platform == 'mobile'" />
                                </div><!-- /col -->
                                <div class="col text-truncate">
                                    <template v-if="platform == 'desktop'">
                                        {{ item.punter }}
                                    </template>
                                    <template v-else> 
                                        <div class="d-flex justify-content-between">
                                            <div class="text-truncate">Apostador</div>
                                            <div class="text-truncate">{{ item.punter }}</div>
                                        </div>
                                    </template>
                                </div><!-- /col -->
                                <div class="col text-truncate">
                                    <template v-if="platform == 'desktop'">
                                        R$ {{ item.value | formatCoin }}
                                    </template>
                                    <template v-else> 
                                        <div class="d-flex justify-content-between">
                                            <div class="text-truncate">Valor</div>
                                            <div class="text-truncate">{{ item.value | formatCoin }}</div>
                                        </div>
                                    </template>
                                </div><!-- /col -->
                                <div class="col text-truncate">
                                    <template v-if="platform == 'desktop'">
                                        R$ {{ item.premium | formatCoin }}
                                    </template>
                                    <template v-else> 
                                        <div class="d-flex justify-content-between">
                                            <div class="text-truncate">Retorno</div>
                                            <div class="text-truncate">{{ item.premium | formatCoin }}</div>
                                        </div>
                                    </template>
                                </div><!-- /col -->
                                <div class="col text-truncate">
                                    <template v-if="platform == 'desktop'">
                                        {{ item.total_hunches }}
                                    </template>
                                    <template v-else> 
                                        <div class="d-flex justify-content-between">
                                            <div class="text-truncate">Palpites</div>
                                            <div class="text-truncate">{{ item.total_hunches }}</div>
                                        </div>
                                    </template>
                                </div><!-- /col -->
                                <div class="col" :class="{'mt-3': platform == 'mobile'}">
                                    <button :class="{'btn-block': platform == 'mobile'}" :id="`btnGroupDropMenuID-${item.id}`" type="button" class="btn btn-secondary btn-sm dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i class="fas fa-ellipsis-v mr-2"></i>Mais
                                    </button>
                                    <div class="dropdown-menu" :aria-labelledby="`btnGroupDropMenuID-${item.id}`">
                                        <div class="dropdown-header text-center text-uppercase">
                                            <div class="d-flex flex-column align-items-center justify-content-center">
                                                <div class="text-info font-bold">{{ item.code }}</div>
                                                <div class="small" v-if="item.pre_bet">{{ item.pre_bet }}</div>
                                            </div>
                                        </div>
                                        <div class="dropdown-divider"></div>
                                        <template v-if="pipe == 3">
                                            <a class="dropdown-item" href="javascript:void(0);" @click="viewBet(item.id)"><i class="fas fa-eye mr-2"></i>Ver</a>
                                            <a class="dropdown-item" href="javascript:void(0);" @click="deleteBetbyID(item.id)"><i class="fas fa-trash mr-2"></i>Excluir</a>
                                            <template v-if="item.situation == 4">
                                                <a class="dropdown-item" href="javascript:void(0);" @click="recoverBet(item.id)"><i class="fas fa-sync mr-2"></i>Recuperar</a>
                                            </template>
                                            <template v-else> 
                                                <a class="dropdown-item" href="javascript:void(0);" @click="cancelBet(item.id)"><i class="fas fa-ban mr-2"></i>Cancelar</a>
                                            </template>
                                            <a class="dropdown-item" href="javascript:void(0);" @click="editOdds(item.id)"><i class="fas fa-edit mr-2"></i>Editar odds</a>
                                            <a class="dropdown-item" href="javascript:void(0);" @click="changeSituation(item.id, item.code, item.situation)"><i class="fas fa-asterisk mr-2"></i>Alterar status</a>
                                            <a class="dropdown-item" href="javascript:void(0);" @click="giveMoneyBack(item.id)" v-if="!item.give_money_back"><i class="fas fa-coins mr-2"></i>Devolver dinheiro</a>
                                            <div class="dropdown-divider"></div>
                                            <a class="dropdown-item" href="javascript:void(0);" @click="viewInfo(item.geolocation)"><i class="fas fa-info-circle mr-2"></i>Exibir informações</a>
                                        </template>
                                        <template v-else>
                                            <a class="dropdown-item" href="javascript:void(0);" @click="viewBet(item.id)"><i class="fas fa-eye mr-2"></i>Ver</a>
                                            <a class="dropdown-item" href="javascript:void(0);" @click="cancelBet(item.id)"><i class="fas fa-ban mr-2"></i>Cancelar</a>
                                        </template>
                                    </div><!-- /dropdown-menu -->
                                </div><!-- /col -->
                            </div><!-- /row -->
                        </div><!-- /row -->
                    </collection-cluster>
                </template>
            </div><!-- /container -->
        </div><!-- /main -->
        <modal name="edit-odds" :width="platform == 'desktop' ? 600 : screen_w" :height="platform == 'desktop' ? 500 : screen_h" :adaptive="true" :classes="['modal-dark','modal-edit-dark']">
            <template v-if="Object.keys(modal).length">
                <header class="modal-edit-header">
                    <div class="row">
                        <div class="col-8 text-light text-uppercase mt-1">Editar odds: <b class="text-warning">{{modal.code}}</b></div>
                        <div class="col-4 text-right">
                            <div class="d-flex align-items-center justify-content-end flex-wrap">
                                <button class="btn btn-sm btn-success" @click="saveOdds">
                                    <template v-if="platform == 'desktop'">
                                        <i class="fas fa-save mr-2"></i>Salvar
                                    </template>
                                    <template v-else>
                                        <i class="fas fa-save"></i>
                                    </template>
                                </button>
                                <div class="modal-edit-close ml-3" role="button" @click="$modal.hide('edit-odds')">
                                    <i class="fal fa-times"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                <div class="modal-edit-scroll-area">
                    <div class="container">
                        <div class="row align-items-center" v-for="(item, index) in Object.values(modal.hunches)" :key="index" :class="item.status">
                            <template v-if="platform == 'desktop'">
                                <div class="col">
                                    <div class="d-flex flex-column">
                                        <div class="text-light text-truncate">{{ item.team_home }}</div>
                                        <div class="text-light text-truncate">{{ item.team_away }}</div>
                                        <div class="text-warning">{{ item.market }}</div>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="d-flex flex-column pt-1">
                                        <div>
                                            <div class="input-group">
                                                <div class="input-group-prepend">
                                                    <div class="input-group-text">$</div>
                                                </div>
                                                <input type="number" class="form-control" v-model="Object.values(modal.hunches)[index].odd" @keypress="updateValue($event)">
                                            </div>
                                        </div>
                                        <div class="mt-1" :data="item.status">
                                            <select class="form-control" v-model="Object.values(modal.hunches)[index].status" name="status-bet" :id="item.id">
                                                <option value="n" :selected="item.status == 'n'">Normal</option>
                                                <option value="c" :selected="item.status == 'c'">Cancelado</option>
                                                <option value="p" :selected="item.status == 'p'">Perdeu</option>
                                                <option value="g" :selected="item.status == 'g'">Ganhou</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-auto mt-1">
                                    <button class="btn btn-block btn-danger" @click="removeEvent(item)">
                                        <i class="fas fa-trash"></i>
                                    </button>
                                </div>
                            </template>
                            <template v-else>
                                <div class="col">
                                    <div class="d-flex flex-column">
                                        <div class="text-light text-truncate">{{ item.team_home }}</div>
                                        <div class="text-light text-truncate">{{ item.team_away }}</div>
                                        <div class="text-warning">{{ item.market }}</div>
                                    </div>
                                </div>
                                <div class="col-auto mt-1">
                                    <button class="btn btn-block btn-danger" @click="removeEvent(item)">
                                        <i class="fas fa-trash"></i>
                                    </button>
                                </div>
                                <div class="w-100"></div>
                                <div class="col-4 text-warning lh-38 pr-0">Cotação</div>
                                <div class="col-8">
                                    <div class="d-flex flex-column pt-1">
                                        <div class="input-group">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">$</div>
                                        </div>
                                            <input type="number" class="form-control" v-model="Object.values(modal.hunches)[index].odd" @keypress="updateValue($event)">
                                        </div>
                                    </div>
                                </div>
                                <div class="w-100"></div>
                                <div class="col-4 text-warning lh-38 pr-0">Situação</div>
                                <div class="col-8">
                                    <div class="mt-1" :data="item.status">
                                        <select class="form-control" v-model="Object.values(modal.hunches)[index].status" name="status-bet" :id="item.id">
                                            <option value="n" :selected="item.status == 'n'">Normal</option>
                                            <option value="c" :selected="item.status == 'c'">Cancelado</option>
                                            <option value="p" :selected="item.status == 'p'">Perdeu</option>
                                            <option value="g" :selected="item.status == 'g'">Ganhou</option>
                                        </select>
                                    </div>
                                </div>                    
                            </template>
                        </div><!-- /row -->
                    </div><!-- /container -->
                </div><!-- /modal-edit-scroll-area -->
            </template>
        </modal><!-- /edit-odds -->
        <modal name="rank-prizes" :width="platform == 'desktop' ? 600 : screen_w" :height="platform == 'desktop' ? 500 : screen_h" :adaptive="true" :classes="['modal-dark','modal-edit-dark']">
            <template>
                <header class="modal-edit-header">
                    <div class="row">
                        <div class="col-8 text-light text-uppercase mt-1">Rank de prêmios</div>
                        <div class="col-4 text-right">
                            <div class="d-flex align-items-center justify-content-end flex-wrap">
                                <button class="btn btn-sm btn-danger" @click="goToSalesman('')">
                                    <i class="fas fa-undo"></i>
                                </button>
                                <div class="modal-edit-close ml-3" role="button" @click="$modal.hide('rank-prizes')">
                                    <i class="fal fa-times"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                <div class="modal-edit-scroll-area overflow-hidden">
                    <div class="modal-ramp">
                        <div class="row" :class="{'flex-nowrap': platform == 'mobile'}">
                            <div class="col">
                                <div class="d-flex justify-content-between">
                                    <div class="mr-3">Apuração</div>
                                    <div>{{info.apuracao | formatCoin}}</div>
                                </div>
                            </div>
                            <div class="col">
                                <div class="d-flex justify-content-between">
                                    <div class="mr-3">Premiação</div>
                                    <div>{{info.premiacao | formatCoin}}</div>
                                </div>
                            </div>
                            <div class="col">
                                <div class="d-flex justify-content-between">
                                    <div class="mr-3">Saldo</div>
                                    <div>{{info.apuracao - info.premiacao | formatCoin}}</div>
                                </div>
                            </div>
                        </div>
                    </div><!-- /modal-ramp -->
                    <div class="container-fluid">
                        <div class="container-scroll bg-white">
                            <div class="row border-top p-3" v-for="(item, index) in salesmanRankOrganizes(salesman)" :key="index" @click="goToSalesman(item.vendedor)" role="button">
                                <div class="col text-truncate" :class="{'text-danger': search == item.vendedor}">{{item.vendedor}}</div>
                                <div class="col-auto text-center">
                                    <span class="badge badge-warning">{{item.total >= 10 ? item.total : '0' + item.total}}</span>
                                </div>
                                <div class="col text-truncate text-right" :class="{'text-danger': search == item.vendedor}">{{item.premiacao | formatCoin}}</div>
                            </div>
                        </div><!-- /container -->
                    </div><!-- /container -->
                </div><!-- /modal-edit-scroll-area -->
            </template>
        </modal><!-- /rank-prizes -->
        <modal name="view-bet" :width="platform == 'desktop' ? 650 : screen_w" :height="platform == 'desktop' ? 500 : screen_h" :adaptive="true" @before-close="beforeClose" :classes="['modal-dark','modal-new']">
            <template v-if="template && betData">
                <div class="modal-topbar">
                    <div class="row align-items-center">
                        <div class="col text-truncate">
                            <i class="fas fa-hashtag align-middle"></i>
                            <span class="text-uppercase text-truncate">
                                {{ betData.code }}
                            </span> 
                        </div>
                        <div class="col-auto text-right">
                            <div class="d-flex flex-row align-items-center justify-content-between">
                                <div @click="generateTicketImage" class="mr-3" role="button" title="Baixar imagem">
                                    <i class="fas fa-download"></i>
                                </div>
                                <div @click="$modal.hide('view-bet')" role="button">
                                    <i class="fal fa-times"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div><!-- /modal-topbar -->
                <div class="modal-view" v-html="template"></div>
            </template>
        </modal><!-- /modal-view -->
      <Loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true"></Loading>
    </main>
</template>

<script>
import Vue from 'vue'
import {api} from '../api'
import Navbar from './Navbar'
import Swal from 'sweetalert2'
import VModal from 'vue-js-modal'
import moment from 'moment-timezone'
import Loading from 'vue-loading-overlay'
import * as rasterizeHTML from 'rasterizehtml'
import CollectionCluster from 'vue-collection-cluster'
import 'vue-loading-overlay/dist/vue-loading.css'
import 'virtual-select-plugin/dist/virtual-select.min.css'
import 'virtual-select-plugin/dist/virtual-select.min.js'

Vue.use(VModal)

const today = moment().tz('America/Recife').format('YYYY-MM-DD');
const heightAdjustMobile = 145;
const heightAdjustDesktop = 206;
const heightAdjust = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent) ? heightAdjustMobile : heightAdjustDesktop;

export default {
    data() {
        return {
            page: 1,
            page_actual: 1,
            total_pages: 1,
            total_bets: 0,
            ticketCode: false,
            pipe: 0,
            bets: [],
            r_bets: [],
            modal: [],
            error: '',
            loadMore: true,
            status: 0,
            search: '', 
            users: [],
            userId: 0,
            info: {
                apuracao: 0,
                premiacao: 0
            },
            betData: '',
            template: '',
            inplay: false,
            salesman: {},
            isActive: 0,
            overLap: true,
            is_live: false,
            loading: false,
            isLoading: false,
            final_date: today,
            initial_date: today,
            nothing_found: false,
            is_accumulated: false,
            screen_w: window.screen.width,
            screen_h: window.screen.height,
            collection: {
                heightType: 'automatic',
                // itemHeight: 742
            },
            screen_innerHeight: window.innerHeight - heightAdjust,
            platform: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent) ? 'mobile' : 'desktop'
        }
    },
    components: {
        Navbar,
        Loading,
        CollectionCluster
    },
    methods: {
        live() {
            if (!this.is_live) {
                this.is_live = true;
                this.search = 'Ao vivo';
            } else {
                this.is_live = false;
                this.search = '';
            }
        },
        accumulated() {
            if (!this.is_accumulated) {
                this.is_accumulated = true;
                this.search = 'Acumuladão';
            } else {
                this.is_accumulated = false;
                this.search = '';
            }
        },
        overLapToggle() {
            this.overLap = !this.overLap;
        },
        async searchBet() {

            const self = this;
            const rex = /^[a-zA-Z0-9]+$/g;

            const { value: code } = await Swal.fire({
                title: 'Buscar aposta',
                input: 'text',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: 'Cancelar',
                customClass: 'sweet-only-custom',
                inputPlaceholder: 'Informe o código da aposta',
                confirmButtonText: 'Buscar',
                inputValidator: (value) => {

                    if (!value) {
                        return 'Informe o código!'
                    } else if (!rex.test(value) || value.toString().length < 8) {
                        return 'O código é inválido!'
                    }
                }
            });

            if (code) {
                self.searchBetByCode(code);
            }
        },
        searchBetByCode(code) {

            const self = this;

            self.bets = [];
            self.error = '';
            self.status = 0;
            self.isActive = 0;
            self.loading = true;
            self.loadMore = false;
            self.nothing_found = false;

            const ticket_code = code.toLowerCase();

            api.get(`dashboard/bets/by-code/${ticket_code}`).then((response) => {
                switch(response.data.result) {
                    case 'success':
                        self.overLap = false;
                        self.bets = self.listBetsStructure(response.data.bets);
                        if (self.ticketCode) self.viewBet(response.data.bets[0]._id);
                    break;
                    case 'nothing_found':
                        self.error = 'Não foi possível encontrar a aposta. Por favor, verifique o código e tente novamente!';
                    break;
                    default:
                        self.error = response.data.message;
                    break;
                }
            }).catch((error) => {
                try {
                    self.error = self.network_erros[error.status]['message'];
                } catch(e) {
                    self.error = self.network_erros[408]['message'];
                }
            }).finally(() => {
                self.loading = false;
            });
        },
        listBets() {

            const self = this;

            self.page = 1;
            self.bets = [];
            self.error = '';
            self.userId = 0;
            self.isActive = 0;
            self.total_bets = 0;
            self.loading = true;
            self.loadMore = true;
            self.page_actual = 1;
            self.total_pages = 1;
            self.nothing_found = false;

            api.get(`dashboard/bets/${self.status}/${self.initial_date}/between/${self.final_date}?live=${self.inplay}`).then((response) => {
                switch(response.data.result) {
                    case 'success':
                        self.overLap = false;
                        self.total_bets = parseInt(response.data.total_bets);
                        self.total_pages = parseInt(response.data.total_pages);
                        self.bets = self.listBetsStructure(response.data.bets);
                    break;
                    case 'nothing_found':
                        self.nothing_found = true;
                    break;
                    default:
                        self.error = response.data.message;
                    break;
                }
            }).catch((error) => {
                try {
                    self.error = self.network_erros[error.status]['message'];
                } catch(e) {
                    self.error = self.network_erros[408]['message'];
                }
            }).finally(() => {
                self.loading = false;
            });
        },
        listBetsStructure(data) {
            return data.map((item) => {
                return {
                    id: item._id,
                    type: 'item',
                    hour: item.hora,
                    date: item.data,
                    code: item.codigo,
                    value: item.valor,
                    horario: item.horario,
                    premium: item.premio,
                    punter: item.apostador,
                    salesman: item.vendedor,
                    hunches: item.palpites,
                    situation: item.situacao,
                    address: item.endereco,
                    vendedor_id: item.vendedor_id,
                    tipo_de_conta: item.tipo_de_conta,
                    pre_bet: item.codigo_pre_aposta || 0,
                    give_money_back: item.dinheiro_devolvido,
                    cash_out: item.encerrada ? 'Encerrada': '',
                    live: item.ao_vivo ? 'Ao vivo' : 'Pré jogo',
                    betbuilder: item.betbuilder ? 'Criar aposta' : '',
                    accumulated: item.acumuladao ? 'Acumuladão': 'Normal',
                    total_hunches: this.countTotalHunches(item.palpites),
                    geolocation: item.hasOwnProperty('geolocation') ? item.geolocation : {},
                    time_table: [moment(item.data).tz('America/Recife').format('DD/MM'), item.hora].join(' ')
                }
            });
        },
        infinityListBets() {

            const self = this;
            const status = self.status == 0 ? self.isActive : self.status;

            if (self.loading) return;

            self.isLoading = true;

            api.get(`dashboard/bets/${status}/${self.initial_date}/between/${self.final_date}?page=${self.page}&live=${self.inplay}&userId=${self.userId}`).then((response) => {
                switch(response.data.result) {
                    case 'success':
                        self.page_actual = parseInt(response.data.page);
                        self.bets.push(...self.listBetsStructure(response.data.bets));
                    break;
                    case 'nothing_found':
                        self.loadMore = false;
                    break;
                    default:
                        self.infinityListBetsError(response.data.message);
                    break;
                }
            }).catch((error) => {
                try {
                    self.infinityListBetsError(self.network_erros[error.status]['message']);
                } catch(e) {
                    self.infinityListBetsError(self.network_erros[408]['message']);
                }
            }).finally(() => {
                self.isLoading = false;
            });
        },
        infinityListBetsError(message) {
            Swal.fire({
				title: 'Atenção!',
				html: `<b>${message}</b><br/></br>Ocorreu um erro ao carregar as apostas, deseja tentar carregar novamente?`,
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				cancelButtonText: 'Cancelar',
				confirmButtonText: 'Sim'
			}).then((result) => {

                if (result.value) {
                    this.infinityListBets();
                }
            });
        },
        viewInfo(data) {

            try {

                if (Object.values(data).length == 0) {
                    Swal.fire(
                        'Atenção!',
                        'Essa aposta não tem informações disponíveis!',
                        'warning'
                    );
                }
                else
                {
                    Swal.fire(
                        'Informações',
                        `<div class="row bg-ddd rounded-top">
                            <div class="col text-truncate pt-2 pb-2">Pais</div>
                            <div class="col text-truncate pt-2 pb-2">${data.pais}</div>
                        </div>
                        <div class="row bg-f1f1f1">
                            <div class="col text-truncate pt-2 pb-2">Estado</div>
                            <div class="col text-truncate pt-2 pb-2">${data.estado}</div>
                        </div>
                        <div class="row bg-ddd">
                            <div class="col text-truncate pt-2 pb-2">Cidade</div>
                            <div class="col text-truncate pt-2 pb-2">${data.cidade}</div>
                        </div>
                        <div class="row bg-f1f1f1">
                            <div class="col text-truncate pt-2 pb-2">Latitude</div>
                            <div class="col text-truncate pt-2 pb-2">${data.latitude}</div>
                        </div>
                        <div class="row bg-ddd">
                            <div class="col text-truncate pt-2 pb-2">Longitude</div>
                            <div class="col text-truncate pt-2 pb-2">${data.longitude}</div>
                        </div>
                        <div class="row bg-f1f1f1 rounded-bottom">
                            <div class="col text-truncate pt-2 pb-2">Provedor (NET)</div>
                            <div class="col text-truncate pt-2 pb-2">${data.provedor}</div>
                        </div>
                        <div class="row mt-3">
                            <div class="col-12 text-center text-truncate">
                                <span class="font-weight-bold">Localização do vendedor</span>
                            </div>
                            <div class="w-100 mt-3 iframe-map rounded">
                                ${this.googleMap(data.latitude, data.longitude)}
                            </div>
                        </div>
                        `
                    )
                }
            } 
            catch(e) 
            {
                Swal.fire(
                    'Atenção!',
                    'Essa aposta não tem informações disponíveis!',
                    'warning'
                );
            }
        },
        filterBet(status) {

            const self = this;

            self.error = '';
            self.isLoading = false;

            if (self.bets.length == 0) self.isActive = status;

            if (self.users.length == 0) {

                self.isLoading = true;

                api.get('dashboard/type/users-active/1').then((response) => {
                    switch(response.data.result) {
                        case 'success':
                            self.users = response.data.users.sort((a, b) => {
                                return self.removeAccents(a.nome).localeCompare(self.removeAccents(b.nome), 'fr', {
                                    ignorePunctuation: true
                                });
                            });
                            self.filterBetDialog(status);
                        break;
                        case 'nothing_found':
                            self.error = 'Nenhum vendedor encontrado!';
                        break;
                        default:
                            self.error = 'Ocorreu um erro, tente novamente!';
                        break;
                    }
                }).catch((error) => {
                    try {
                        self.error = self.network_erros[error.status]['message'];
                    } catch(e) {
                        self.error = self.network_erros[408]['message'];
                    }
                }).finally(() => {
                    self.isLoading = false;
                });
            }
            else
            {
                self.filterBetDialog(status);
            }
        },
        filterLive(status) {
            this.inplay = true;
            this.status = status;
        },
        async changeSituation(id, code, status) {

            const self = this;

            const { value: formValues } = await Swal.fire({
                title: 'Alterar status',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: 'Cancelar',
                confirmButtonText: 'Alterar',
                html: `
                    <label class="d-block text-left mb-0">Você está alterando o status da aposta: <b class="text-uppercase">${code}</b></label>
                    <select id="swal-input1" class="swal2-input w-100">
                        <option value="1" ${status == 1 ? 'selected': ''}>Aberta</option>
                        <option value="2" ${status == 2 ? 'selected': ''}>Ganhou</option>
                        <option value="3" ${status == 3 ? 'selected': ''}>Perdeu</option>
                        <option value="4" ${status == 4 ? 'selected': ''}>Cancelada</option>
                    </select>
                `,
                focusConfirm: false,
                preConfirm: () => {
                    return {
                        situacao: parseInt(document.getElementById('swal-input1').value)
                    }
                },
            });

            if (formValues instanceof Object) {

                Swal.fire({
                    title: 'Confirmação',
                    html: `Você deseja mesmo alterar o status da aposta: <b class="text-uppercase">${code}</b></p>`,
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    cancelButtonText: 'Cancelar',
                    confirmButtonText: 'Sim'
                }).then((result) => {

                    if (result.value) {

                        self.isLoading = true;

                        api.put(`dashboard/change-status/${id}/${formValues.situacao}`).then((response) => {
                            switch(response.data.result) {
                                case 'success':
                                    Swal.fire(
                                        'Parabéns!',
                                        'As alterações foram salvas com sucesso!',
                                        'success'
                                    );
                                break;
                                default:
                                    Swal.fire(
                                        'Atenção!',
                                        response.data.message,
                                        'warning'
                                    );
                                break;
                            }
                        }).catch((error) => {
                            try {
                                Swal.fire(
                                    'Falha',
                                    self.network_erros[error.status]['message'],
                                    'error'
                                );
                            } catch(e) {
                                Swal.fire(
                                    'Falha',
                                    self.network_erros[408]['message'],
                                    'error'
                                );
                            }
                        }).finally(() => {
                            self.isLoading = false;
                        });                        
                    }
                });
            }
        },
        async filterBetDialog(status) {

            const self = this;

            const { value: formValues } = await Swal.fire({
                title: 'Filtrar apostas',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: 'Cancelar',
                confirmButtonText: 'Buscar',
                html: `
                    <label class="d-block text-left mb-0" for="swal-input1">Vendedor</label>
                    <div id="swal-virtual-select" data-style="swall"></div>

                    <label class="d-block text-left mb-0" for="swal-input2">Data inicial</label>
                    <input id="swal-input2" class="swal2-input" value="${today}" type="date">

                    <label class="d-block text-left mb-0" for="swal-input3">Data final</label>
                    <input id="swal-input3" class="swal2-input" value="${today}" type="date">
                `,
                customClass: {
                    actions: 'swal2-actions swal-virtual-select'
                },
                focusConfirm: false,
                preConfirm: () => {
                    return {
                        id: document.getElementById('swal-virtual-select').value,
                        data1: document.getElementById('swal-input2').value,
                        data2: document.getElementById('swal-input3').value
                    }
                },
                onOpen: () => {

                    const options = [];
                    const users = self.users;
                    const total_users = users.length;

                    options.push({
                        value: 'all',
                        label: 'Todos',
                        description: 'Filtrar todos os vendedores'
                    });

                    if (total_users) {
                        for (const user of users) {
                            options.push({
                                value: user._id,
                                label: user.nome,
                                description: user.endereco
                            });
                        }
                    }

                    window.VirtualSelect.init({
                        ele: '#swal-virtual-select',
                        search: true,
                        placeholder: 'Selecione um vendedor',
                        noOptionsText: 'Nenhuma opção disponível',
                        noSearchResultsText: 'Nenhum resultado encontrado',
                        searchPlaceholderText: `Buscar vendedor (${total_users})...`,
                        hasOptionDescription: true,
                        options: options
                    });
                }
            });

            if (formValues instanceof Object) {
                
                self.initial_date = formValues.data1;
                self.final_date = formValues.data2;

                if (formValues.id == '') {
                    formValues.id = 'all';
                }

                if (formValues.id == 'all') {
                
                    if (self.status != status) {
                        self.status = status;
                    } else {
                        self.listBets();
                    }
                }
                else
                {
                    self.bets = [];
                    self.page = 1;
                    self.error = '';
                    self.status = 0;
                    self.userId = 0;
                    self.total_bets = 0;
                    self.loadMore = true;
                    self.page_actual = 1;
                    self.total_pages = 1;
                    self.loading = true;
                    self.isActive = status;
                    self.nothing_found = false;

                    api.get(`dashboard/bets/${status}/${self.initial_date}/between/${self.final_date}?userId=${formValues.id}`).then((response) => {
                        switch(response.data.result) {
                            case 'success':
                                self.overLap = false;
                                self.userId = formValues.id;
                                self.total_bets = parseInt(response.data.total_bets);
                                self.total_pages = parseInt(response.data.total_pages);
                                self.bets = self.listBetsStructure(response.data.bets);
                            break;
                            case 'nothing_found':
                                self.nothing_found = true;
                            break;
                            default:
                                self.error = response.data.message;
                            break;
                        }
                    }).catch((error) => {
                        try {
                            self.error = self.network_erros[error.status]['message'];
                        } catch(e) {
                            self.error = self.network_erros[408]['message'];
                        }
                    }).finally(() => {
                        self.loading = false;
                    });                    
                }
            }
        },
        viewBet(id) {

            const self = this;

            self.isLoading = true;
            self.ticketCode = false;

            api.get(`view-bet/${id}`).then((response) => {
                switch(response.data.result) {
                    case 'success':
                        self.mountView(id, response.data.hunches);
                    break;
                    default:
                        Swal.fire(
                            'Falha',
                            response.data.message,
                            'error'
                        );
                    break;
                }
            }).catch((error) => {
                try {
                    Swal.fire(
                        'Falha',
                        self.network_erros[error.status]['message'],
                        'error'
                    );
                } catch(e) {
                    Swal.fire(
                        'Falha',
                        self.network_erros[408]['message'],
                        'error'
                    );
                }
            }).finally(() => {
                self.isLoading = false;
            });
        },
        async delBet() {

            const self = this;
            const rex = /^[a-zA-Z0-9]+$/g;

            const { value: code } = await Swal.fire({
                title: 'Excluir aposta',
                input: 'text',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: 'Cancelar',
                customClass: 'sweet-only-custom',
                inputPlaceholder: 'Informe o código da aposta',
                confirmButtonText: 'Excluir',
                inputValidator: (value) => {

                    if (!value) {
                        return 'Informe o código!'
                    } else if (!rex.test(value)) {
                        return 'O código é inválido!'
                    }
                }
            });

            if (code) {

                Swal.fire({
                    title: 'Excluir aposta',
                    html: `Você deseja mesmo excluir essa aposta <b class="text-uppercase">"${code}"</b> ?`,
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    cancelButtonText: 'Cancelar',
                    confirmButtonText: 'Sim'
                }).then((result) => {

                    if (result.value) {

                        self.isLoading = true;

                        const index = self.bets.findIndex((item) => item.code == code.toLowerCase());

                        api.delete(`/dashboard/delete-bet/by-code/${code}`).then((response) => {
                            switch(response.data.result) {
                                case 'success':

                                    if (index != -1) {
                                        self.bets.splice(index, 1);
                                    }

                                    Swal.fire(
                                        'Parabéns!',
                                        'Aposta foi excluída com sucesso!',
                                        'success'
                                    );
                                break;
                                default:
                                    Swal.fire(
                                        'Falha',
                                        response.data.message,
                                        'error'
                                    );
                            }
                        }).catch((error) => {
                            try {
                                Swal.fire(
                                    'Falha',
                                    self.network_erros[error.status]['message'],
                                    'error'
                                );
                            } catch(e) {
                                Swal.fire(
                                    'Falha',
                                    self.network_erros[408]['message'],
                                    'error'
                                );
                            }
                        }).finally(() => {
                            self.isLoading = false;
                        });
                    }
                });
            }
        },
        deleteBetbyID(id) {
            
            const self = this;
            const index = self.bets.findIndex((item) => item.id == id);

            Swal.fire({
				title: 'Excluir aposta',
				html: `Você deseja mesmo excluir essa aposta <b class="text-uppercase">"${self.bets[index].code}"</b> ?`,
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				cancelButtonText: 'Cancelar',
				confirmButtonText: 'Sim'
			}).then((result) => {

                if (result.value) {

                    self.isLoading = true;
                    
                    api.delete(`dashboard/delete-bet/by-id/${id}`).then((response) => {
                        switch (response.data.result) {
                            case 'success':

                                self.bets.splice(index, 1);

                                Swal.fire(
                                    'Parabéns!',
                                    'Aposta foi excluída com sucesso!',
                                    'success'
                                )
                                break;
                            default:
                                Swal.fire(
                                    'Falha',
                                    response.data.message,
                                    'error'
                                );
                        }
                    }).catch((error) => {
                        try {
                            Swal.fire(
                                'Falha',
                                self.network_erros[error.status]['message'],
                                'error'
                            );
                        } catch(e) {
                            Swal.fire(
                                'Falha',
                                self.network_erros[408]['message'],
                                'error'
                            );
                        }
                    }).finally(() => {
                        self.isLoading = false;
                    });
                }
            });
        },
        cancelBet(id) {

            const self = this;
            const code = self.bets.find((item) => item.id == id).code;

            Swal.fire({
				title: 'Cancelar aposta',
				html: `Você deseja mesmo cancelar essa aposta <b class="text-uppercase">"${code}"</b> ?`,
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				cancelButtonText: 'Não',
				confirmButtonText: 'Sim'
			}).then((result) => {

                if (result.value) {
                    
                    self.isLoading = true;

                    api.put(`dashboard/cancel-bet/${id}`).then((response) => {
                        switch(response.data.result) {
                            case 'success':
                                Swal.fire(
                                    'Parabéns!',
                                    'Aposta foi cancelada com sucesso!',
                                    'success'
                                )
                            break;
                            default:
                                Swal.fire(
                                    'Falha',
                                    response.data.message,
                                    'error'
                                );
                        }
                    }).catch((error) => {
                        try {
                            Swal.fire(
                                'Falha',
                                self.network_erros[error.status]['message'],
                                'error'
                            );
                        } catch(e) {
                            Swal.fire(
                                'Falha',
                                self.network_erros[408]['message'],
                                'error'
                            );
                        }
                    }).finally(() => {
                        self.isLoading = false;
                    });
                }
            });
        },
        recoverBet(id) {

            const self = this;
            const code = self.bets.find((item) => item.id == id).code;

            Swal.fire({
				title: 'Recuperar aposta',
				html: `Você deseja mesmo recuperar essa aposta <b class="text-uppercase">"${code}"</b> ?`,
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				cancelButtonText: 'Cancelar',
				confirmButtonText: 'Sim'
			}).then((result) => {

                if (result.value) {
                    
                    self.isLoading = true;

                    api.put(`dashboard/recover-bet/${id}`).then((response) => {
                        switch(response.data.result) {
                            case 'success':
                                Swal.fire(
                                    'Parabéns!',
                                    'Aposta foi recuperada com sucesso!',
                                    'success'
                                )
                            break;
                            default:
                                Swal.fire(
                                    'Falha',
                                    response.data.message,
                                    'error'
                                );
                        }
                    }).catch((error) => {
                        try {
                            Swal.fire(
                                'Falha',
                                self.network_erros[error.status]['message'],
                                'error'
                            );
                        } catch(e) {
                            Swal.fire(
                                'Falha',
                                self.network_erros[408]['message'],
                                'error'
                            );
                        }
                    }).finally(() => {
                        self.isLoading = false;
                    });
                }
            });
        },
        editOdds(id) {

            this.modal = this.bets.find((item) => item.id == id);

            if (this.modal) {
                this.$modal.show('edit-odds');
            }
        },
        removeEvent(item) {
            
            const self = this;

            if (typeof(self.modal.hunches[item.id]) == 'undefined') {
                Swal.fire(
                    'Atenção!',
                    'Esse evento não pode ser removido!',
                    'warning'
                );
            }
            else
            {
                self.$delete(self.modal.hunches, item.id);
            }
        },
        saveOdds() {
            
            const self = this;
            const bet = self.modal;
            const index = self.bets.findIndex((item) => item.id == bet.id);

            self.$modal.hide('edit-odds');

            if (Object.keys(bet.hunches).length == 0) {
                Swal.fire(
                    'Atenção!',
                    'Essa aposta não contém nenhum palpite. Sendo assim, não será possível salvar as alterações!',
                    'warning'
                );
            } else if (Object.values(bet.hunches).some((item) => item.odd == '')) {
                Swal.fire(
                    'Atenção!',
                    'Informe o valor da cotação!',
                    'warning'
                );
            }
            else
            {
                Swal.fire({
                    title: 'Alterar odds',
                    html: `Você deseja mesmo alterar as odds da aposta <b class="text-uppercase">"${self.bets[index].code}"</b> ?`,
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    cancelButtonText: 'Cancelar',
                    confirmButtonText: 'Sim'
                }).then((result) => {

                    if (result.value) {

                        self.isLoading = true;

                        api.post('dashboard/edit-odds', {
                            id: bet.id,
                            value: bet.value,
                            hunches: bet.hunches
                        }).then((response) => {
                            switch (response.data.result) {
                                case 'success':

                                    self.bets[index].premium = response.data.premium;

                                    Swal.fire(
                                        'Parabéns!',
                                        'As alterações foram salvas com sucesso!',
                                        'success'
                                    )
                                    break;
                                default:
                                    Swal.fire(
                                        'Falha',
                                        response.data.message,
                                        'error'
                                    );
                            }
                        }).catch((error) => {
                            try {
                                Swal.fire(
                                    'Falha',
                                    self.network_erros[error.status]['message'],
                                    'error'
                                );
                            } catch(e) {
                                Swal.fire(
                                    'Falha',
                                    self.network_erros[408]['message'],
                                    'error'
                                );
                            }
                        }).finally(() => {
                            self.isLoading = false;
                        });
                    }
                });
            }
        },
        giveMoneyBack(id) {

            const self = this;
            const code = self.bets.find((item) => item.id == id).code;

            Swal.fire({
				title: 'Devolver dinheiro',
				html: `Você deseja mesmo devolver o dinheiro da aposta <b class="text-uppercase">"${code}"</b> ?`,
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				cancelButtonText: 'Cancelar',
				confirmButtonText: 'Sim'
			}).then((result) => {

                if (result.value) {
                    
                    self.isLoading = true;

                    api.put(`dashboard/give-money-back-bet/${id}`).then((response) => {
                        switch(response.data.result) {
                            case 'success':
                                Swal.fire(
                                    'Parabéns!',
                                    'Dinheiro devolvido com sucesso!',
                                    'success'
                                )
                            break;
                            default:
                                Swal.fire(
                                    'Falha',
                                    response.data.message,
                                    'error'
                                );
                        }
                    }).catch((error) => {
                        try {
                            Swal.fire(
                                'Falha',
                                self.network_erros[error.status]['message'],
                                'error'
                            );
                        } catch(e) {
                            Swal.fire(
                                'Falha',
                                self.network_erros[408]['message'],
                                'error'
                            );
                        }
                    }).finally(() => {
                        self.isLoading = false;
                    });
                }
            });
        },
        mountView(id, hunches) {
            
            try {

                let html = ``;

                const self = this;
                const bet = self.bets.find((item) => item.id == id);

                if (bet instanceof Object) {

                    let anteior_value = 0;
                    let anteior_value_team = 0; 
                    let hunches_order = self.orderByScheduleByBet(hunches);

                    for (let key in hunches_order) {
                        let palpite = hunches_order[key];

                        if (anteior_value != palpite.league) {
                            html += `
                            <div class="league">
                                <div>${palpite.league}</div>
                            </div>
                            `;
                        }

                        if (palpite.betbuilder) {

                            html += `<div class="event">`;

                            if (anteior_value_team != palpite.id) {
                                html += `
                                    <div class="item">
                                        <div class="item-col">${palpite.team_home}</div>
                                        <div class="item-col">${self.dateTicket(palpite.schedule)}</div>
                                    </div>
                                    <div class="item">
                                        <div class="item-col">${palpite.team_away}</div>
                                        <div class="item-col">${palpite.live ? 'Ao vivo' : 'Pré-jogo'}</div>
                                    </div>
                                    <div class="item">
                                        <div class="item-col-3 font-bold">Modalidade</div>
                                        <div class="item-col-3 font-bold">Situação</div>
                                        <div class="item-col-3 font-bold">Odds</div>
                                    </div>
                                `;
                            }

                            html += `
                                <div class="item">
                                    <div class="item-col-3">Criar aposta</div>
                                    <div class="item-col-3 ${palpite.situation == 1 || palpite.status == 'g' ? 'hit' : palpite.situation == 2 || palpite.status == 'p' ? 'lose' : 'default'}">${palpite.status == 'g' ? 'Acertou' : palpite.status == 'p' ? 'Perdeu' : palpite.status == 'c' ? 'Cancelado' : self.findSituation(palpite.situation)}</div>
                                    <div class="item-col-3">R$&nbsp;${palpite.odd}</div>
                                </div>
                                <div class="item">
                                    ${palpite.situation == 7 ? `
                                        <div class="alert alert-warning mt-2 text-center">${self.findMarketSituation(palpite)}</div>
                                    ` : `
                                        <div class="item-market font-bold">${self.findMarketSituation(palpite)}</div>
                                    `}
                                </div>
                            `;

                            html += `</div>`;

                        } else {
                            html += `
                            <div class="event">
                                <div class="item">
                                    <div class="item-col">${palpite.team_home}</div>
                                    <div class="item-col">${self.dateTicket(palpite.schedule)}</div>
                                </div>
                                <div class="item">
                                    <div class="item-col">${palpite.team_away}</div>
                                    <div class="item-col">R$&nbsp;${palpite.odd}</div>
                                </div>
                                <div class="item">
                                    <div class="item-col-3 font-bold">Modalidade</div>
                                    <div class="item-col-3 font-bold">Situação</div>
                                    <div class="item-col-3 font-bold">Ao vivo</div>
                                </div>
                                <div class="item">
                                    <div class="item-col-3">${self.findModality(palpite.sport)}</div>
                                    <div class="item-col-3 ${palpite.situation == 1 || palpite.status == 'g' ? 'hit' : palpite.situation == 2 || palpite.status == 'p' ? 'lose' : 'default'}">${palpite.status == 'g' ? 'Acertou' : palpite.status == 'p' ? 'Perdeu' : palpite.status == 'c' ? 'Cancelado' : self.findSituation(palpite.situation)}</div>
                                    <div class="item-col-3">${palpite.live ? 'Sim' : 'Não'}</div>
                                </div>
                                ${palpite.live ? `
                                    <div class="item">
                                        <div class="item-col-3 font-bold">Placar</div>
                                        <div class="item-col-3 font-bold">Tempo</div>
                                        <div class="item-col-3 font-bold">Período</div>
                                    </div>
                                    <div class="item">
                                        <div class="item-col-3">${palpite.info.score}</div>
                                        <div class="item-col-3">${palpite.info.time}' Min</div>
                                        <div class="item-col-3">${palpite.info.period == '1st Half' ? '1º Tempo' : palpite.info.period == '2nd Half' ? '2º Tempo' : 'Intervalo'}</div>
                                    </div>    
                                ` : ''}
                                <div class="item"> 
                                    ${palpite.situation == 7 ? `
                                        <div class="alert alert-warning mt-2 text-center">${self.findMarketSituation(palpite)}</div>
                                    ` : `
                                        <div class="item-market font-bold">${self.findMarketSituation(palpite)}</div>
                                    `}
                                </div>
                            </div>
                            `;
                        }

                        anteior_value = palpite.league;
                        anteior_value_team = palpite.id;
                    }

                    let template = `
                     <div class="ticket-virtual">
                        <div class="header">
                            <div class="item">
                                <div class="logo">
                                    <img src="${self.logo}" border="0" alt="logo" />
                                </div>
                            </div>
                            <div class="item text-right">
                                <div class="font-bold">Código</div>
                                <div>${bet.code}</div>
                            </div>
                        </div>
                        <div class="wrap">
                            <h3 class="text-center ${bet.situation == 1 ? 'd-none' : 'd-block text-underline'}">
                                ${bet.give_money_back ? 'Aposta devolvida' : bet.situation == 2 ? 'Aposta premiada' : bet.situation == 3 ? 'Aposta perdida' : bet.situation == 4 ? 'Aposta cancelada' : 'Sem informações'}    
                            </h3>
                            <div class="wrap-item">
                                <div>Vendedor</div>
                                <div>${bet.salesman}</div>
                            </div>
                            <div class="wrap-item">
                                <div>Apostador</div>
                                <div>${bet.punter}</div>
                            </div>
                            <div class="wrap-item">
                                <div>Valor apostado</div>
                                <div>R$&nbsp;${self.format_coin(bet.value)}</div>
                            </div>
                            <div class="wrap-item">
                                <div>Possível prêmio</div>
                                <div>R$&nbsp;${self.format_coin(bet.premium)}</div>
                            </div>
                            <div class="wrap-item">
                                <div>Horário da aposta</div>
                                <div>${self.convertDate(bet.date) + '&nbsp;-&nbsp;' + bet.hour}</div>
                            </div>
                            <h5 class="text-center">
                                Qtd. Palpites: ${self.countTotalHunches(hunches)}    
                            </h5>
                            <div class="wrap-content">
                                <div class="wrap-content-item">
                                    ${html}
                                </div>
                            </div>
                        </div>
                     </div>
                    `;

                    self.betData = bet;
                    self.template = template;
                    self.$modal.show('view-bet');
                } 
                else 
                {
                    Swal.fire(
                        'Atenção!',
                        'Aposta não encontrada!',
                        'error'
                    );
                }
            } 
            catch(e) 
            {
                Swal.fire(
                    'Atenção!',
                   'Ocorreu um erro ao exibir a aposta, atualiza a página e tente novamente!',
                    'error'
                );
            }
        },
        generateTicketImage() {
            try {

                if (!this.template) return;

                const self = this;
                const iframe = document.createElement('iframe');
                const {themeStyleColor, themeTextColor, themeForeground} = self.getThemeStyle();

                if (themeStyleColor && themeTextColor && themeForeground) {

                   let page = `
                        <html>
                            <head>
                                <meta charset="utf-8">
                                <style>
                                    @import url(/css/ticket.css);
                                    :root {
                                        --ticket-style-color: ${themeStyleColor};
                                        --ticket-text-color: ${themeTextColor};
                                        --ticket-foreground: ${themeForeground};
                                    }
                                    html, body {
                                        margin: 0;
                                        padding: 0;
                                        width: 100%;
                                        height: auto;
                                        background-color: var(--ticket-style-color);
                                    }
                                    h5 {
                                        margin-top: 0!important;
                                    }
                                </style>
                            </head>
                            <body>
                                ${self.template}
                            </body>
                        </html>
                    `;

                    self.isLoading = true;

                    iframe.style.width = '600px';
                    iframe.style.height = 'auto';
                    iframe.src = 'about:blank';
                    iframe.style.visibility = 'hidden';  

                    document.body.appendChild(iframe);
                    iframe.contentWindow.document.write(page);

                    setTimeout(() => {

                        let canvas = document.createElement('canvas');
                        let ticket = iframe.contentWindow.document.querySelector('.ticket-virtual');

                        canvas.width = ticket.offsetWidth;
                        canvas.height = ticket.offsetHeight;

                        rasterizeHTML.drawHTML(page, canvas).then(() => {

                            self.isLoading = false;

                            iframe.remove();

                            let link = document.createElement('a');
                            link.download = (self.betData ? `TICKET-${(self.betData.code).toUpperCase()}.jpeg` : `${Math.floor(Math.random() * 1000000)}.jpeg`);
                            link.href = canvas.toDataURL('image/jpeg');
                            link.click();
                        });
                    }, 100);
                } 
                else 
                {
                    Swal.fire(
                        'Atenção!',
                        'Não foi possível gerar a imagem do comprovante!',
                        'error'
                    );
                }
            } 
            catch(e) 
            {
                Swal.fire(
                    'Atenção!',
                    e.message,
                    'error'
                );
            }
        },
        searchDayBets() {
            
            if (this.initial_date == '') {
                Swal.fire(
                    'Atenção!',
                    'Informe a data inicial!',
                    'warning'
                );
            } else if (this.final_date == '') {
                Swal.fire(
                    'Atenção!',
                    'Informe a data final!',
                    'warning'
                );
            } else {
                this.listBets();
            }
        },
        salesmanOrganizes(arr) {
            return arr.reduce((unique, o) => {
                if(!unique.some(obj => obj.salesman === o.salesman)) {
                    unique.push(o);
                }
                return unique;
            },[]).sort((a, b) => {
                return this.removeAccents(a.salesman).toLowerCase().localeCompare(this.removeAccents(b.salesman).toLowerCase())
            });
        },
        searchBets(event) {
           this.search = event.target.value;
        },
        convertDate(date) {
            return moment(date).tz('America/Recife').format('DD/MM/YYYY');
        },
        convertDateTicket(date) {
            return moment(date).tz('America/Recife').format('DD/MM - HH:mm');
        },
        removeAccents(str) {
            
            let string = str;
            let map = {a : /[\xE0-\xE6]/g, A : /[\xC0-\xC6]/g, e : /[\xE8-\xEB]/g, E : /[\xC8-\xCB]/g, i : /[\xEC-\xEF]/g, I : /[\xCC-\xCF]/g, o : /[\xF2-\xF6]/g, O : /[\xD2-\xD6]/g, u : /[\xF9-\xFC]/g, U : /[\xD9-\xDC]/g, c : /\xE7/g, C : /\xC7/g, n : /\xF1/g, N : /\xD1/g};

            for (let key in map) {
                 let rex = map[key];
                 string = string.replace(rex, key);
            }

            return string;
        },
        formatCoin(n, c, d, t, s, i, j) {
            c = isNaN(c = Math.abs(c)) ? 2 : c, d = d == undefined ? "," : d, t = t == undefined ? "." : t, s = n < 0 ? "-" : "", i = parseInt(n = Math.abs(+n || 0).toFixed(c)) + "", j = (j = i.length) > 3 ? j % 3 : 0;
            return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
        },
        updateValue(event) {
           let value = event.target.value;

           value = value.replace(/\D/g,"");  
           value = value.replace(/[0-9]{12}/," ");  
           value = value.replace(/(\d{1})(\d{8})$/,"$1.$2");
           value = value.replace(/(\d{1})(\d{5})$/,"$1.$2");  
           value = value.replace(/(\d{1})(\d{1,2})$/,"$1.$2"); 
        
           event.target.value = value.replace(',','.');
        },
        salesmanRankOrganizes(obj) {

            const arr = [];

            for (let key in obj) {
                // if (obj[key].hasOwnProperty(''))
                arr.push(obj[key]);
            }

            return arr.sort((a, b) => {
                return b.premiacao - a.premiacao;
            });
        },
        getTheme() {
            try {
                return window.localStorage.getItem('theme');
            } catch(e) {
                return 'theme-default';
            }
        },
        goToSalesman(salesman) {
            
            if (this.search == salesman) {
                this.search = '';
            } else {
                this.search = salesman;
            }

            this.$modal.hide('rank-prizes');
        },
        async filterByMoney() {

            let b = (this.bets.length < this.r_bets.length) ? this.r_bets : this.bets;

            this.r_bets = b;
            
            const { value: formValues } = await Swal.fire({
                title: 'Filtrar por valor',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: 'Cancelar',
                confirmButtonText: 'Filtrar',
                html: `
                    <label class="d-block text-left mb-0">Valor inicial</label>
                    <input id="swal-input1" class="swal2-input" type="text" autocomplete="off" spellcheck="false">

                    <label class="d-block text-left mb-0">Valor final</label>
                    <input id="swal-input2" class="swal2-input" type="text" autocomplete="off" spellcheck="false">
                `,
                focusConfirm: false,
                preConfirm: () => {
                    return {
                        initial_value: document.getElementById('swal-input1').value,
                        final_value: document.getElementById('swal-input2').value
                    }
                },
                onOpen: () => {
                    document.getElementById('swal-input1').addEventListener("input", (e) => {
                        return e.target.value = e.target.value.replace(/[^0-9]/g, '');
                    });

                    document.getElementById('swal-input2').addEventListener("input", (e) => {
                        return e.target.value = e.target.value.replace(/[^0-9]/g, '');
                    });
                }
            });

            if (formValues instanceof Object) {

                if (formValues.initial_value.toString() == '') {
                    Swal.fire(
                        'Atenção!',
                        'Informe o valor inicial!',
                        'warning'
                    );
                } else if (formValues.final_value.toString() == '') {
                    Swal.fire(
                        'Atenção!',
                        'Informe o valor final!',
                        'warning'
                    );
                } 
                else 
                {
                    let bets = b.filter((item) => parseFloat(item.value) >= parseFloat(formValues.initial_value) && parseFloat(item.value) <= parseFloat(formValues.final_value));
                    
                    if (bets.length == 0) {
                        Swal.fire(
                            'Atenção!',
                            'Nenhuma aposta encontrada para o valor informado!',
                            'warning'
                        );
                    } 
                    else 
                    {
                        this.bets = bets;
                    }
                }
            }
            else
            {
                this.bets = this.r_bets;
            }
        },
        async filterByHour() {

            let b = (this.bets.length < this.r_bets.length) ? this.r_bets : this.bets;

            this.r_bets = b;
            
            const { value: formValues } = await Swal.fire({
                title: 'Filtrar por horário',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: 'Cancelar',
                confirmButtonText: 'Filtrar',
                html: `
                    <label class="d-block text-left mb-0">Horário inicial</label>
                    <input id="swal-input1" class="swal2-input" type="time" autocomplete="off" spellcheck="false">

                    <label class="d-block text-left mb-0">Horário final</label>
                    <input id="swal-input2" class="swal2-input" type="time" autocomplete="off" spellcheck="false">
                `,
                focusConfirm: false,
                preConfirm: () => {
                    return {
                        initial_hour: document.getElementById('swal-input1').value,
                        final_hour: document.getElementById('swal-input2').value
                    }
                },
            });

            if (formValues instanceof Object) {

                if (formValues.initial_hour.toString() == '') {
                    Swal.fire(
                        'Atenção!',
                        'Informe o horário inicial!',
                        'warning'
                    );
                } else if (formValues.final_hour.toString() == '') {
                    Swal.fire(
                        'Atenção!',
                        'Informe o horário final!',
                        'warning'
                    );
                } 
                else 
                {
                    let bets = b.filter((item) => item.hour >= formValues.initial_hour && item.hour <= formValues.final_hour);
                    
                    if (bets.length == 0) {
                        Swal.fire(
                            'Atenção!',
                            'Nenhuma aposta encontrada para o horário informado!',
                            'warning'
                        );
                    } 
                    else 
                    {
                        this.bets = bets;
                    }
                }
            }
            else
            {
                this.bets = this.r_bets;
            }
        },
        onScrollToBottom() {
            
            if (this.page < this.total_pages && this.page == this.page_actual) {
                this.page++;
            }

            if ((this.page == this.total_pages) && this.loadMore) {
                this.loadMore = false;
            }
        },
        beforeClose() {
            this.betData = '';
            this.template = '';
        },
    },
    filters: {
        formatCoin(n, c, d, t, s, i, j) {
            c = isNaN(c = Math.abs(c)) ? 2 : c, d = d == undefined ? "," : d, t = t == undefined ? "." : t, s = n < 0 ? "-" : "", i = parseInt(n = Math.abs(+n || 0).toFixed(c)) + "", j = (j = i.length) > 3 ? j % 3 : 0;
            return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
		}
    },
    computed: {
        filteredListBets() {
            return this.bets.filter((item) => {
                return this.removeAccents(item.salesman).toLowerCase().trim().includes(this.removeAccents(this.search).toLowerCase().trim()) || this.removeAccents(item.punter).toLowerCase().trim().includes(this.removeAccents(this.search).toLowerCase().trim()) || item.code.toLowerCase().trim().includes(this.search.toLowerCase().trim()) || this.removeAccents(item.live).toLowerCase().trim().includes(this.removeAccents(this.search).toLowerCase().trim()) || this.removeAccents(item.tipo_de_conta).toLowerCase().trim().includes(this.removeAccents(this.search).toLowerCase().trim()) || this.removeAccents(item.cash_out).toLowerCase().trim().includes(this.removeAccents(this.search).toLowerCase().trim()) || this.removeAccents(item.betbuilder).toLowerCase().trim().includes(this.removeAccents(this.search).toLowerCase().trim());
            }).sort((a, b) => {

                if (this.removeAccents(a.salesman).trim() < this.removeAccents(b.salesman).trim()) return -1;
                if (this.removeAccents(a.salesman).trim() > this.removeAccents(b.salesman).trim()) return 1;

                if (a.horario < b.horario) return 1;
                if (a.horario > b.horario) return -1;

                return 0;
            });
        }
    },
    watch: {
        inplay() {
            this.listBets();
        },
        page(page) {

            if (!this.loadMore && page == 1) return;

            this.infinityListBets();
        },
        status(value) {

            if (this.loading) return;
            if (!value) return;

            this.listBets();
        },
        bets(apostas) {

            const self = this;

            self.salesman = {};
            self.info.apuracao = 0;
            self.info.premiacao = 0;

            if (apostas.length) {

                const select = document.querySelector('#virtual-select');
                const salesmanOrganizes = self.salesmanOrganizes(apostas);

                const options = salesmanOrganizes.map((item) => {
                    return {
                        label: item.salesman,
                        value: item.salesman,
                        description: item.address
                    }
                });
                
                select.setOptions(options);

                apostas.forEach((item) => {

                    self.salesman[item.vendedor_id] = self.salesman[item.vendedor_id] != undefined ? self.salesman[item.vendedor_id] = {
                        vendedor: item.salesman,
                        total: self.salesman[item.vendedor_id].total += 1,
                        premiacao: self.salesman[item.vendedor_id].premiacao += parseFloat(item.premium)
                    } : {
                        total: 1,
                        vendedor: item.salesman,
                        premiacao: parseFloat(item.premium)
                    };

                    self.info.apuracao += parseFloat(item.value);
                    self.info.premiacao += parseFloat(item.premium);
                });
            }
        },
        ticketCode(value) {
            if (!value) {
                this.$router.replace({
                    'query': {}
                });
            }
        },
        search(value) {
            const filtersSearch = ['Ao vivo', 'Criar aposta', 'Encerrada', 'Vendedor', 'Cliente'];
            if (this.overLap && filtersSearch.includes(value)) {
                this.overLap = false;
            }
        },
        '$route': {
            immediate: true,
            handler() {
                if (this.$route.query.hasOwnProperty('ticket')) {
                    this.ticketCode = true;
                    this.searchBetByCode(this.$route.query.ticket);
                }
            }
        }
    },
    beforeMount() {
        try {
            const {pipe} = JSON.parse(window.localStorage.getItem('auth'));
            this.pipe = pipe;
        } catch(e) {
            this.pipe = 0;
        }
    },
    mounted() {

        const self = this;

        window.VirtualSelect.init({
            ele: '#virtual-select',
            search: true,
            placeholder: 'Selecione um vendedor',
            noOptionsText: 'Nenhuma opção disponível',
            noSearchResultsText: 'Nenhum resultado encontrado',
            searchPlaceholderText: `Buscar vendedor...`,
            hasOptionDescription: true
        });

        if (document.querySelector('#virtual-select')) {
            document.querySelector('#virtual-select').addEventListener('change', function() {
                self.overLap = false;
                self.search = this.value;
            });
        }
    },
    created() {
        window.addEventListener('resize', () => {
            this.screen_w = window.screen.width;
            this.screen_h = window.screen.height;
            this.platform = this.isMobile();
        });
    }
}
</script>

<style scoped>
.main {
    margin-top: 80px;
}
.dropdown-toggle:after {
  border: none;
}
.col-sm {
    background-color: #ddd
}
.btn .badge {
    top: 3px!important;
}
.mt-3.mb-3 .row {
    margin-left: 0;
    margin-right: 0;
}
.row-head {
    padding: .75rem;
    background-color: #212529;
    color: #fff;
    vertical-align: bottom;
    text-align: center;
}
.row-salesman {
    padding: .75rem;
    background-color: #ffc107;
    color: #fff;
    vertical-align: bottom;
    text-align: center;
}
.row-item.winner .row-salesman {
    background-color: #4CAF50!important;
}
.row-category {
    padding: .75rem;
    color: #fff;
    vertical-align: bottom;
    text-align: center;
}
.row-category .col.text-truncate {
    position: relative;
    z-index: 0;
    top: 3px;
}
.row-category .col.text-truncate hr {
    border-top-color: #666;
}
.row.row-item:not(.winner) {
    background-color: #212529;
}
.row.row-item:not(.winner):nth-of-type(odd) {
    background-color: rgba(255,255,255,.05);
}
.row.row-item.winner {
    background-color: #006204;
}
.row.row-item.winner:nth-of-type(odd) {
    background-color: rgb(0 110 4);
}
.scroll-y {
    overflow-y: scroll
}
.d-contents {
    display: contents!important;
}
.modal-edit-scroll-area {
    width: 100%;
    padding-bottom: 10px;
    overflow: hidden auto;
    height: calc(100% - 56px);
}
.modal-edit-scroll-area::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background: #4b4b4b;
    border-radius: 0;
}
.modal-edit-scroll-area::-webkit-scrollbar-thumb {
    background: #373737;
    border-radius: 0;
}
.modal-edit-close {
    color: #fff;
}
.modal-edit-close:hover {
    color: #ddd;
}
input[type=date]::-webkit-clear-button {
    display: none; /* Hide the button */
    -webkit-appearance: none; /* turn off default browser styling */
}
input[type=date]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    display: none;
}
.drop-left {
    margin-left: -100%!important;
}
.card.no-border {
    border: none!important;
}
.bg-secondary-2 {
    background-color: #7a848c!important;
}
.lh-38 {
    line-height: 38px;
}
.collection-cluster {
    overflow-x: auto;
}
.collection-cluster:not(.desktop) {
    margin-top: 132px;
}
.collection-cluster.desktop::-webkit-scrollbar {
  width: 9px;
  height: 9px;
}
.collection-cluster.desktop::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
.collection-cluster.desktop::-webkit-scrollbar-thumb {
  background: #e1e1e1;
  border: 0px none #ffffff;
  border-radius: .25rem;
}
.collection-cluster.desktop::-webkit-scrollbar-thumb:hover {
  background: #ffffff;
}
.collection-cluster.desktop::-webkit-scrollbar-thumb:active {
  background: #ddd;
}
.collection-cluster.desktop::-webkit-scrollbar-track {
  background: #666666;
  border: 0px none #ffffff;
  border-radius: .25rem;
}
.collection-cluster.desktop::-webkit-scrollbar-track:hover {
  background: #666666;
}
.collection-cluster.desktop::-webkit-scrollbar-track:active {
  background: #333333;
}
.collection-cluster.desktop::-webkit-scrollbar-corner {
  background: transparent;
}
.overlap {
    width: 100%;
    height: 100%;
}
.overlap.overlap-fixed {
    position: fixed;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    background-color: #333;
    right: 0;
    left: 0;
    top: 69px;
    z-index: 1;
}
.overlap.overlap-fixed.overlap-hide {
    height: auto!important;
}
.overlap.overlap-fixed.overlap-hide .overlap-content {
    display: none!important;;
}
.modal-ramp {
    padding: 0.5em 1em;
    background-color: #444;
}
.modal-ramp .row .col {
    color: #fff;
}
.modal-ramp .row.flex-nowrap {
    overflow-x: auto;
}
.modal-ramp .row .col:nth-child(2) {
    border-left: 1px solid #555;
    border-right: 1px solid #555;
}
.modal-edit-scroll-area.overflow-hidden .container-fluid {
    height: 100%;
    padding: 0!important;
}
.container-fluid .container-scroll {
    height: calc(100% - 33px);
    overflow: hidden auto;
}
.modal-view {
    width: 100%;
    height: calc(100% - 36.8px);
    overflow-y: auto;
    overflow-x: hidden;
    color: var(--theme);
    background-color: var(--gray-dark);
}
.modal-view::-webkit-scrollbar {
    width: 4px!important;
    height: 4px!important;
    background: var(--gray)!important;
    border-radius: 0;
}
.modal-view::-webkit-scrollbar-thumb {
    width: 4px!important;
    height: 4px!important;
    background: var(--gray-dark)!important;
}
.modal-view .border-bottom {
    border-bottom: 1px solid var(--foreground);
}
</style>